var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 pa-0"},[_c('v-col',{attrs:{"cols":"8"}},[(!_vm.isEvaluator)?_c('v-row',{staticClass:"pa-0 ma-0 mb-4",attrs:{"justify":"end"}},[_c('bz-button',{staticClass:"text-capitalize",attrs:{"icon":"mdi-plus","width":"200","title":"Create Training","type":"Submit"},on:{"click":function($event){return _vm.createDialog()}}})],1):_vm._e(),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0"},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"subtitle-1"},[_vm._v("Available Trainings")])]),_c('v-divider'),_c('v-card',{staticClass:"overflow-vertical-auto",attrs:{"loading":_vm.loadingTrainings,"flat":"","height":"50vh","max-height":"50vh"}},[(_vm.trainings.length === 0 && !_vm.loadingTrainings)?_c('v-card',{staticClass:"overflow-vertical-hidden",attrs:{"flat":"","height":"inherit"}},[_c('div',{staticClass:"d-flex flex-column full-width justify-center align-center fill-height"},[_c('div',{staticClass:"headline secondaryText--text font-weight-light"},[_vm._v("Training not available yet")]),(!_vm.isEvaluator)?_c('bz-button',{staticClass:"flat mt-6",attrs:{"icon":"mdi-plus","width":"200","title":"Create Training","type":"Submit"},on:{"click":function($event){return _vm.createDialog()}}}):_vm._e()],1)]):_vm._l((_vm.trainings),function(training,index){return _c('v-card',{key:index,staticClass:"ma-6 px-4 py-3",attrs:{"hover":"","max-width":"522","to":!_vm.isEvaluator
                  ? {
                      name: 'course-home',
                      query: {
                        course: training.title,
                        id: training.id,
                      },
                    }
                  : {
                      name: 'evaluator-course-home',
                      query: {
                        course: training.title,
                        id: training.id,
                      },
                    }}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"body-2"},[_vm._v(_vm._s(training.title))]),(!_vm.isEvaluator)?_c('div',{staticClass:"ma-0 pa-0"},[(training.isActive)?_c('bz-active-chip',{staticClass:"primary caption textLight--text",attrs:{"active":"ACTIVE"}}):_c('bz-active-chip',{staticClass:"textOrange caption textLight--text",attrs:{"active":"NOT ACTIVE"}})],1):_vm._e()])])})],2)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"350"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"scroll"},[_c('v-card-title',{staticClass:"primary textLight--text font-weight-light d-flex justify-center"},[_vm._v("Create Training")]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('v-row',{staticClass:"px-8 mt-6"},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"sm":"12","cols":"12"}},[_c('v-text-field',{attrs:{"rules":_vm.requiredRules,"label":"Training Name"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"sm":"12","cols":"12"}},[_c('v-text-field',{attrs:{"rules":_vm.requiredRules,"label":"Training Description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c('div',{staticClass:"d-flex justify-center px-8"},[_c('bz-button',{staticClass:"flat full-width my-6",attrs:{"loading":_vm.loading,"title":"Create","type":"Submit"}})],1)],1)],1)],1),_c('BzNotification',{model:{value:(_vm.notification),callback:function ($$v) {_vm.notification=$$v},expression:"notification"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }