<template>
  <v-container class="ma-0 pa-0">
    <v-col cols="8">
      <v-row v-if="!isEvaluator" justify="end" class="pa-0 ma-0 mb-4">
        <bz-button
          icon="mdi-plus"
          @click="createDialog()"
          width="200"
          title="Create Training"
          type="Submit"
          class="text-capitalize"
        ></bz-button>
      </v-row>
      <v-row class="pa-0 ma-0">
        <v-col class="pa-0 ma-0">
          <v-card>
            <v-card-title>
              <span class="subtitle-1">Available Trainings</span>
            </v-card-title>
            <v-divider />
            <v-card
              :loading="loadingTrainings"
              flat
              height="50vh"
              max-height="50vh"
              class="overflow-vertical-auto"
            >
              <v-card
                flat
                height="inherit"
                v-if="trainings.length === 0 && !loadingTrainings"
                class="overflow-vertical-hidden"
              >
                <div class="d-flex flex-column full-width justify-center align-center fill-height">
                  <div
                    class="headline secondaryText--text font-weight-light"
                  >Training not available yet</div>
                  <bz-button
                    v-if="!isEvaluator"
                    icon="mdi-plus"
                    @click="createDialog()"
                    width="200"
                    title="Create Training"
                    type="Submit"
                    class="flat mt-6"
                  ></bz-button>
                </div>
              </v-card>
              <v-card
                hover
                max-width="522"
                class="ma-6 px-4 py-3"
                v-else
                v-for="(training, index) in trainings"
                :key="index"
                :to="
                  !isEvaluator
                    ? {
                        name: 'course-home',
                        query: {
                          course: training.title,
                          id: training.id,
                        },
                      }
                    : {
                        name: 'evaluator-course-home',
                        query: {
                          course: training.title,
                          id: training.id,
                        },
                      }
                "
              >
                <div class="d-flex justify-space-between">
                  <div class="body-2">{{ training.title }}</div>
                  <div v-if="!isEvaluator" class="ma-0 pa-0">
                    <bz-active-chip
                      v-if="training.isActive"
                      class="primary caption textLight--text"
                      active="ACTIVE"
                    />
                    <bz-active-chip
                      v-else
                      class="textOrange caption textLight--text"
                      active="NOT ACTIVE"
                    />
                  </div>
                </div>
              </v-card>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <v-dialog v-model="dialog" width="350">
      <v-card class="scroll">
        <v-card-title
          class="primary textLight--text font-weight-light d-flex justify-center"
        >Create Training</v-card-title>
        <v-form @submit.prevent="validate" ref="form" lazy-validation>
          <v-row class="px-8 mt-6">
            <v-col class="pa-0 ma-0" sm="12" cols="12">
              <v-text-field v-model="title" :rules="requiredRules" label="Training Name"></v-text-field>
            </v-col>
            <v-col class="ma-0 pa-0" sm="12" cols="12">
              <v-text-field
                v-model="description"
                :rules="requiredRules"
                label="Training Description"
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="d-flex justify-center px-8">
            <bz-button :loading="loading" title="Create" type="Submit" class="flat full-width my-6"></bz-button>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
    <BzNotification v-model="notification"></BzNotification>
  </v-container>
</template>
<style scoped>
.scroll {
  overflow: hidden;
}
</style>
<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import BzActiveChip from "@/beez-admin/components/BzActiveChip";
import BzButton from "@shared/components/BzSubmitButton";
import BzNotification from "@/shared/components/BzNotification";

export default {
  name: "elevation-training-card",
  data() {
    return {
      requiredRules: [v => !!v || "Field is required"],
      disableCreateBtn: true,
      loadingTrainings: false,
      title: "",
      description: "",
      trainings: [],
      trainingConfigurationId: "",
      accountId: "",
      file: null,
      dialog: false,
      loading: false,
      isEvaluator: false
    };
  },
  components: {
    BzButton,
    BzActiveChip,
    BzNotification
  },
  async created() {
    this.accountId = this.currentUser.roles[0].accountId;
    if (this.$router.currentRoute.name == "evaluator-training") {
      this.isEvaluator = true;
      if (this.evaluatorTrainings.length > 0) {
        this.setTrainings();
      } else {
        this.loadingTrainings = true;
      }
      // await this.fetchEvaluatorTraining();
    } else {
      await this.getAvailableTrainings();
    }
    // await this.getConfigurationId();
  },
  watch: {
    evaluatorTrainings: {
      handler() {
        this.loadingTrainings = false;
        this.setTrainings();
      }
    }
  },
  computed: {
    ...mapFields("auth", ["currentUser"]),
    ...mapFields("training", [
      "availableTrainings",
      "availableEvaluatorTrainings"
    ]),
    ...mapFields("evaluator", ["evaluatorTrainings"]),
    ...mapFields("admin", ["elevationModule"])
  },
  methods: {
    ...mapActions("training", {
      fetchAvailableTrainings: "fetchAvailableTrainings",
      createNewTraining: "createNewTraining"
    }),
    ...mapActions("admin", {
      getElevationModules: "getElevationModules"
    }),
    createDialog() {
      this.dialog = true;
    },
    setTrainings() {
      this.trainings = [];
      if (this.evaluatorTrainings.length > 0) {
        this.evaluatorTrainings.map(element => {
          this.trainings.push({
            id: element._id,
            title: element.name,
            description: element.description
          });
        });
      }
    },
    async getConfigurationId() {
      // const elevationModule = await this.getElevationModules(
      //   this.currentUser.accounts[0]
      // );
      // const trainingConfiguration = this.elevationModule.configurations.find(
      //   c => c.name === "trainingIds"
      // );
      // this.trainingConfigurationId = trainingConfiguration._id;
    },
    async getAvailableTrainings() {
      try {
        this.loadingTrainings = true;
        const trainings = await this.fetchAvailableTrainings({});

        if (this.availableTrainings.length > 0) {
          this.availableTrainings.map(element => {
            this.trainings.push({
              id: element._id,
              title: element.name,
              description: element.description,
              isActive: element.beezInCode ? true : false
            });
          });
        }
      } catch (error) {
        // alert(error.message + "\t Failed to fetch Training card !");
      } finally {
        this.loadingTrainings = false;
      }
    },

    async createTraining() {
      this.loading = true;
      try {
        const training = await this.createNewTraining({
          name: this.title,
          description: this.description,
          accountId: this.accountId
        });
        this.trainings.push({
          id: training._id,
          title: training.name,
          description: training.description
        });
      } catch (error) {
        this.notify("An unexpected error has occured");
      } finally {
        this.title = "";
        this.description = "";
        this.loading = false;
        this.dialog = false;
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.createTraining();
      } else {
        return;
      }
    }
  }
};
</script>
